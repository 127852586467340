<!-- TODO: Cambiar esta ruta a preseleccion -->
<!-- TODO: Cambiar la forma de evaluar por [PreseleccionEvaluacionId]-->
<script>
import Swal from 'sweetalert2';

export default {
    name: "AdministradorDeVacantes",
    data() {
        return {
            listaDePostulantes: [],
            postulanteDatos: {},
            resultEvaluacion: null,
            opcionesDeEvaluacion: [
                { id: null, description: 'Pendiente' },
                { id: 1, description: 'Aprobado' },
                { id: 0, description: 'Rechazado' }
            ],
            loader: false
        }
    },
    mounted() {
        this.getListaDeVacantes();
    },
    methods: {
        async getListaDeVacantes() {


            const response = await this.$store.getters.fetchGet({ path: `Postulacion/postulantes/${this.$route.params.vacanteId}` })
            const vacantes = await response.json();
            this.listaDePostulantes = new Array(...vacantes)
            console.log(this.listaDePostulantes);
        },

        openModalEvaluar(data) {
            this.postulanteDatos = data;
            this.resultEvaluacion = data.estadoEvaluacion;
            this.$bvModal.show('modal-evaluar');
        },
        evaluar() {
            this.loader = true;
            this.$store.getters.fetchPut({ path: `Postulacion/EstadoEvaluacion/${this.postulanteDatos.id}`, data: this.resultEvaluacion })
            .then(response => {
                if( response.ok ) {
                    return response.json()
                }
                throw new Error('Error al guardar los datos personales')
            })
            .then( () => {
                Swal.fire({
                    title: 'Exito!',
                    text: 'Se ha guardado el estado de la evaluación',
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                })
                this.postulanteDatos.estadoEvaluacion = this.resultEvaluacion;
                this.$bvModal.hide('modal-evaluar');
                this.resultEvaluacion = null;
            })
            .catch( () => {
                Swal.fire({
                    title: 'Error!',
                    text: 'No se ha guardado el estado de la evaluación',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                })
            })
            .finally( () => {
                this.loader = false;
            })

        },
        readOption(value) {
            return this.opcionesDeEvaluacion.find( item => item.id === value).description
        }
    }
}


</script>

<template>
    <b-container fluid>
        <b-modal id="modal-evaluar" class="modal-sq" size="lg" centered hide-footer>
            <template #modal-header="{ close }">
                <div class="d-flex justify-content-between" style="width: 100%;">
                    <p class="mb-0 h4"><strong>Estado de la evaluación</strong></p>
                    <b-button @click=" close()" class="btn btn-pm" variant="danger">
                        <i class="fas fa-times"></i>
                    </b-button>
                </div>
            </template>
            <template #default>
                <form @submit.prevent="evaluar">
                    <b-form-select v-model="resultEvaluacion" text-field="description" value-field="id"
                        :options="opcionesDeEvaluacion">
                    </b-form-select>
                    <button type="submit" class="btn btn-primary mt-2 d-flex justify-content-center" :disabled="loader" style="min-width: 75px;">
                        <span v-if="!loader">
                            Guardar
                        </span>
                        <span v-else>
                            <i class="fas fa-spinner fa-spin"></i>
                        </span>
                    </button>
                </form>
            </template>
        </b-modal>
        <b-row>
            <b-col sm="12">
                <card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title"><b>Proceso de preselección</b></h4>
                    </template>
                    <!-- <div class="text-right px-4 mt-3">
                        <button class="mx-1 btn btn-success" @click="$router.push({ name: 'NuevaVacante' })"><i
                                class="fas fa-plus"></i>Crear vacante</button>
                    </div> -->
                    <div class="table-responsive px-4 mt-3">
                        <table id="datatable_cargos"
                            class="table table-bordered table-responsive-md table-striped text-center">
                            <thead>
                                <tr>
                                    <th>Nombres y apellidos</th>
                                    <th>Identificación</th>
                                    <th>Fecha de postulación</th>
                                    <th>Ver postulación</th>
                                    <th>Es de talento interno</th>
                                    <th>Aprobado</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="postulante in listaDePostulantes" :key="postulante.id">
                                    <td>{{ postulante.nombres + ' ' + postulante.apellidos }}</td>
                                    <td>{{ postulante.numeroDocumento }}</td>
                                    <td>{{ $store.getters.useMoment(postulante.fechaDePostulacion) }}</td>
                                    <td>
                                        <b-button variant="primary" size="sm" @click.prevent="
                                            $router.push({ name: 'RegistroDePostulacion', params: { id: postulante.id } })
                                            ">Ver más</b-button>
                                    </td>
                                    <td> {{ postulante.personaId ? 'si' : 'no' }} </td>
                                    <td>
                                        {{ readOption(postulante.estadoEvaluacion) }}
                                    </td>
                                    <td class="options">
                                        <div class="option-element">
                                            <span @click="openModalEvaluar(postulante)">
                                                <!-- Evaluar -->
                                                <i data-toggle="tooltip" data-placement="top" title="Evaluar"
                                                    class="pointer fas fa-solid fa-clipboard px-1 text-success"></i>
                                            </span>
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </card>
            </b-col>
        </b-row>
    </b-container>
</template>

<style scoped>
.options {
    display: flex;
    justify-content: center;
    align-items: center;
}

.option-element {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
}
</style>